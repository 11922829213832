<template>
	<div class="tcontainer">
		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="pull-right" style="margin: 5px;">
				Total Centers: {{ rows?.length }}
			</div>
			<div class="title">Centers</div>
		</div>
		<table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>ID</th>
					<th>Center Name</th>
					<th>Contact Person</th>
					<th>Phone</th>
					<th>Single View</th>
					<th>Double View</th>
					<th>Multi View</th>
					<th>Both View</th>
					<th>Other View</th>
					<th>OPG</th>
					<th>ECG</th>
					<th>TMT</th>
					<th>PFT</th>
					<th>EEG</th>
					<th v-if="false">Commission</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in rows" :key="row">
					<td>{{ row.id }}</td>
					<td>{{ row.name }}</td>
					<td>{{ row.contact_person }}</td>
					<td>{{ row.phone }}</td>
					<td>&#8377;{{ row.agent_price_single_view }} / &#8377;{{ row.price_single_view }}</td>
					<td>&#8377;{{ row.agent_price_double_view }} / &#8377;{{ row.price_double_view }}</td>
					<td>&#8377;{{ row.agent_price_multi_view }} / &#8377;{{ row.price_multi_view }}</td>
					<td>&#8377;{{ row.agent_price_both_view }} / &#8377;{{ row.price_both_view }}</td>
					<td>&#8377;{{ row.agent_price_other_view }} / &#8377;{{ row.price_other_view }}</td>
					<td>&#8377;{{ row.agent_price_opg }} / &#8377;{{ row.price_opg }}</td>
					<td>&#8377;{{ row.cardiology_agent_price }} / &#8377;{{ row.cardiology_price }}</td>
					<td>&#8377;{{ row.tmt_agent_price }} / &#8377;{{ row.tmt_price }}</td>
					<td>&#8377;{{ row.pulmonology_agent_price }} / &#8377;{{ row.pulmonology_price }}</td>
					<td>&#8377;{{ row.neurology_agent_price }} / &#8377;{{ row.neurology_price }}</td>
					<td v-if="false">
						<table class="table">
							<tbody>
								<tr>
									<td>Single View: </td>
									<td>&#8377;{{ row.agent_price_single_view }} / &#8377;{{ row.price_single_view }}</td>
								</tr>
								<tr>
									<td>Double View: </td>
									<td>&#8377;{{ row.agent_price_double_view }} / &#8377;{{ row.price_double_view }}</td>
								</tr>
								<tr>
									<td>Multi View: </td>
									<td>&#8377;{{ row.agent_price_multi_view }} / &#8377;{{ row.price_multi_view }}</td>
								</tr>
								<tr>
									<td>Both View: </td>
									<td>&#8377;{{ row.agent_price_both_view }} / &#8377;{{ row.price_both_view }}</td>
								</tr>
								<tr>
									<td>Other View: </td>
									<td>&#8377;{{ row.agent_price_other_view }} / &#8377;{{ row.price_other_view }}</td>
								</tr>
								<tr>
									<td>Cardiology: </td>
									<td>&#8377;{{ row.cardiology_agent_price }} / &#8377;{{ row.cardiology_price }}</td>
								</tr>
								<tr>
									<td>TMT: </td>
									<td>&#8377;{{ row.tmt_agent_price }} / &#8377;{{ row.tmt_price }}</td>
								</tr>
							</tbody>
						</table>

					</td>
				</tr>
			</tbody>
		</table>

		<form action="" @submit.prevent="putCenter">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								Add Center
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched }"
												v-model="fdata.name" @keyup="makeCenterCode" @blur="fcontrol.name.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.name?.hasError">
												<span v-if="fcontrol.name.errors.required">You must enter name</span>
												<span v-if="fcontrol.name.errors.max">Name can contain maximum
													{{ fcontrol.name.errors.max.maxLength }} characters</span>
											</div>
											<label>Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.code?.hasError && fcontrol.code.touched }"
												v-model="fdata.code" @blur="fcontrol.code.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.code?.hasError">
												<span v-if="fcontrol.code.errors.required">You must enter code</span>
												<span v-if="fcontrol.code.errors.max">Code can contain maximum
													{{ fcontrol.code.errors.max.maxLength }} characters</span>
											</div>
											<label>Code</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
												v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
												<span v-if="fcontrol.email.errors.required">You must enter email</span>
												<span v-if="fcontrol.email.errors.max">Email can contain maximum
													{{ fcontrol.email.errors.max.maxLength }} characters</span>
											</div>
											<label>Email</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
												v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
												<span v-if="fcontrol.password.errors.required">You must enter password</span>
												<span v-if="fcontrol.password.errors.max">Password can contain maximum
													{{ fcontrol.password.errors.max.maxLength }} characters</span>
											</div>
											<label>Password</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.contact_person?.hasError && fcontrol.contact_person.touched }"
												v-model="fdata.contact_person" @blur="fcontrol.contact_person.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.contact_person?.hasError">
												<span v-if="fcontrol.contact_person.errors.required">You must enter contact
													person</span>
												<span v-if="fcontrol.contact_person.errors.max">Contact Person can contain maximum
													{{ fcontrol.contact_person.errors.max.maxLength }} characters</span>
											</div>
											<label>Contact Person</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.phone?.hasError && fcontrol.phone.touched }"
												v-model="fdata.phone" @blur="fcontrol.phone.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.phone?.hasError">
												<span v-if="fcontrol.phone.errors.required">You must enter phone</span>
												<span v-if="fcontrol.phone.errors.max">Phone can contain maximum
													{{ fcontrol.phone.errors.max.maxLength }} characters</span>
											</div>
											<label>Phone</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.address?.hasError && fcontrol.address.touched }"
												v-model="fdata.address" @blur="fcontrol.address.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.address?.hasError">
												<span v-if="fcontrol.address.errors.required">You must enter address</span>
												<span v-if="fcontrol.address.errors.max">Address can contain maximum
													{{ fcontrol.address.errors.max.maxLength }} characters</span>
											</div>
											<label>Address</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.show_disclaimer?.hasError && fcontrol.show_disclaimer.touched }"
												v-model="fdata.show_disclaimer" @blur="fcontrol.show_disclaimer.touched = true">
												<option value="" disabled selected>Select Is Active</option>
												<option value="y">Yes</option>
												<option value="n">No</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.show_disclaimer?.hasError">
												<span v-if="fcontrol.show_disclaimer.errors.required">You must select show
													disclaimer</span>
												<span v-if="fcontrol.show_disclaimer.errors.in">Invalid show disclaimer</span>
											</div>
											<label>Show Disclaimer</label>
										</div>
										<div class="ecg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_price?.hasError && fcontrol.cardiology_price.touched }"
													v-model="fdata.cardiology_price" @blur="fcontrol.cardiology_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('cardiology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.cardiology_price?.hasError">
													<span v-if="fcontrol.cardiology_price.errors.required">You must enter ECG test
														price</span>
												</div>
												<label>ECG Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_price?.hasError && fcontrol.tmt_price.touched }"
													v-model="fdata.tmt_price" @blur="fcontrol.tmt_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('tmt_price')" />
												<div class="invalid-feedback" v-if="fcontrol.tmt_price?.hasError">
													<span v-if="fcontrol.tmt_price.errors.required">You must enter TMT test
														price</span>
												</div>
												<label>TMT Test Price</label>
											</div>
										</div>
										<div class="pft-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_price?.hasError && fcontrol.pulmonology_price.touched }"
													v-model="fdata.pulmonology_price" @blur="fcontrol.pulmonology_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('pulmonology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.pulmonology_price?.hasError">
													<span v-if="fcontrol.pulmonology_price.errors.required">You must enter PFT test price</span>
												</div>
												<label>PFT Test Price</label>
											</div>
										</div>
										<div class="eeg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.neurology_price?.hasError && fcontrol.neurology_price.touched }"
													v-model="fdata.neurology_price" @blur="fcontrol.neurology_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('neurology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.neurology_price?.hasError">
													<span v-if="fcontrol.neurology_price.errors.required">You must enter EEG test
														price</span>
												</div>
												<label>EEG Test Price</label>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="center-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_single_view?.hasError && fcontrol.price_single_view.touched }"
													v-model="fdata.price_single_view" @blur="fcontrol.price_single_view.touched = true"
													placeholder=" " @keyup="doubleRate('single_view'); calculateAgentPrice('price_single_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_single_view?.hasError">
													<span v-if="fcontrol.price_single_view.errors.required">You must enter price
														single view</span>
												</div>
												<label>Price Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_double_view?.hasError && fcontrol.price_double_view.touched }"
													v-model="fdata.price_double_view" @blur="fcontrol.price_double_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_double_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_double_view?.hasError">
													<span v-if="fcontrol.price_double_view.errors.required">You must enter price
														double view</span>
												</div>
												<label>Price Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_multi_view?.hasError && fcontrol.price_multi_view.touched }"
													v-model="fdata.price_multi_view" @blur="fcontrol.price_multi_view.touched = true"
													placeholder=" " @keyup="doubleRate('multi_view'); calculateAgentPrice('price_multi_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_multi_view?.hasError">
													<span v-if="fcontrol.price_multi_view.errors.required">You must enter price multi
														view</span>
												</div>
												<label>Price Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_both_view?.hasError && fcontrol.price_both_view.touched }"
													v-model="fdata.price_both_view" @blur="fcontrol.price_both_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_both_view')" readonly />
												<div class="invalid-feedback" v-if="fcontrol.price_both_view?.hasError">
													<span v-if="fcontrol.price_both_view.errors.required">You must enter price both
														view</span>
												</div>
												<label>Price Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_other_view?.hasError && fcontrol.price_other_view.touched }"
													v-model="fdata.price_other_view" @blur="fcontrol.price_other_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_other_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_other_view?.hasError">
													<span v-if="fcontrol.price_other_view.errors.required">You must enter price other
														view</span>
												</div>
												<label>Price Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_opg?.hasError && fcontrol.price_opg.touched }"
													v-model="fdata.price_opg" @blur="fcontrol.price_opg.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_opg')" />
												<div class="invalid-feedback" v-if="fcontrol.price_opg?.hasError">
													<span v-if="fcontrol.price_opg.errors.required">You must enter price OPG</span>
												</div>
												<label>Price OPG</label>
											</div>
										</div>

										<div class="agent-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_single_view?.hasError && fcontrol.agent_price_single_view.touched }"
													v-model="fdata.agent_price_single_view"
													@blur="fcontrol.agent_price_single_view.touched = true" readonly placeholder=" " />

												<label>Commission Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_double_view?.hasError && fcontrol.agent_price_double_view.touched }"
													v-model="fdata.agent_price_double_view"
													@blur="fcontrol.agent_price_double_view.touched = true" readonly placeholder=" " />

												<label>Commission Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_multi_view?.hasError && fcontrol.agent_price_multi_view.touched }"
													v-model="fdata.agent_price_multi_view"
													@blur="fcontrol.agent_price_multi_view.touched = true" readonly placeholder=" " />

												<label>Commission Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_both_view?.hasError && fcontrol.agent_price_both_view.touched }"
													v-model="fdata.agent_price_both_view"
													@blur="fcontrol.agent_price_both_view.touched = true" readonly placeholder=" " />

												<label>Commission Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_other_view?.hasError && fcontrol.agent_price_other_view.touched }"
													v-model="fdata.agent_price_other_view"
													@blur="fcontrol.agent_price_other_view.touched = true" readonly placeholder=" " />

												<label>Commission Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_opg?.hasError && fcontrol.agent_price_opg.touched }"
													v-model="fdata.agent_price_opg"
													@blur="fcontrol.agent_price_opg.touched = true" readonly placeholder=" " />

												<label>Commission OPG</label>
											</div>

											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_agent_price?.hasError && fcontrol.cardiology_agent_price.touched }"
													v-model="fdata.cardiology_agent_price"
													@blur="fcontrol.cardiology_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission ECG</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_agent_price?.hasError && fcontrol.tmt_agent_price.touched }"
													v-model="fdata.tmt_agent_price"
													@blur="fcontrol.tmt_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission TMT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_agent_price?.hasError && fcontrol.pulmonology_agent_price.touched }"
													v-model="fdata.pulmonology_agent_price"
													@blur="fcontrol.pulmonology_agent_price.touched = true" readonly placeholder=" " />

												<label>Agent Commission PFT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.neurology_agent_price?.hasError && fcontrol.neurology_agent_price.touched }"
													v-model="fdata.neurology_agent_price"
													@blur="fcontrol.neurology_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG</label>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { Modal } from 'bootstrap'
export default {

	data() {
		return {
			dateRange: "",
			totalReports: 0,
			rows: [],
			centers: [],
			reports: {},
			testPrice: 0,
			doctorPrice: 0,
			agentPrice: 0,
			isSuper: false,

			addModal: null,
			defaultRates: {},

			fdata: {
				code: '',
				name: '',
				email: '',
				password: '',
				contact_person: '',
				phone: '',
				address: '',
				created_at: '',
				is_active: 'y',
				minimum_balance: 0,
				show_disclaimer: 'y',
				price_single_view: '',
				price_double_view: '',
				price_multi_view: '',
				price_both_view: '',
				price_other_view: '',
				price_opg: '',
				doctor_price_single_view: '',
				doctor_price_double_view: '',
				doctor_price_multi_view: '',
				doctor_price_both_view: '',
				doctor_price_other_view: '',
				doctor_price_opg: '',
				doctor_ids: '',
				cardiology_price: '',
				cardiology_doctor_price: '',
				tmt_price: '',
				tmt_doctor_price: '',
				cardiologist_ids: '',
				pulmonology_price: '',
				pulmonology_doctor_price: '',
				pulmonologist_ids: '',
				neurology_price: '',
				neurology_doctor_price: '',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				neurology_agent_price: '',
			},
			fvalidator: {
				code: 'required|max:10',
				name: 'required|max:50',
				email: 'required|max:50',
				password: 'required|max:30',
				contact_person: 'required|max:50',
				phone: 'required|max:20',
				address: 'required|max:255',
				created_at: '',
				is_active: 'required|in:y,n',
				minimum_balance: '',
				show_disclaimer: 'required|in:y,n',
				price_single_view: 'required',
				price_double_view: 'required',
				price_multi_view: 'required',
				price_both_view: 'required',
				price_other_view: 'required',
				price_opg: 'required',
				doctor_price_single_view: 'required|notgt:price_single_view',
				doctor_price_double_view: 'required|notgt:price_double_view',
				doctor_price_multi_view: 'required|notgt:price_multi_view',
				doctor_price_both_view: 'required|notgt:price_both_view',
				doctor_price_other_view: 'required|notgt:price_other_view',
				doctor_price_opg: 'required|notgt:price_opg',
				doctor_ids: '',
				cardiology_price: 'required',
				cardiology_doctor_price: 'required|notgt:cardiology_price',
				tmt_price: 'required',
				tmt_doctor_price: 'required|notgt:tmt_price',
				cardiologist_ids: '',
				pulmonology_price: 'required',
				pulmonology_doctor_price: 'required|notgt:pulmonology_price',
				pulmonologist_ids: '',
				neurology_price: 'required',
				neurology_doctor_price: 'required|notgt:neurology_price',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				neurology_agent_price: '',
			}
		}
	},
	async mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.loadLazyData();


		this.defaultRates = await this.get("agent/load-defualt-rates");

		let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
		let minCols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
		

		for(let i=0; i < mins.length; i++){
			let m = Number(this.defaultRates[mins[i]]);
			if(m){
				this.fcontrol[minCols[i]].validators.push({
					name: 'gte',
					params: [m]
				})
			}
		}
	},
	methods: {
		makeCenterCode(){
			if(this.editId) return;
			let code = '';

			let name = this.fdata.name ? this.fdata.name.toUpperCase().split(" ") : [];
			for(let p of name){
				if(p){
					code = code + p[0]
				}
			}

			let d = new Date();
			code = code + String(d.getFullYear()).substr(-2) + '' + (d.getMonth()+1) + '' + d.getDate();

			this.fdata.code = code;
		},
		async loadLazyData() {
			let res = await this.get("agent/load-centers");
			this.rows = res.rows;
		},
		calculateAgentPrice(col){
			let row = this.defaultRates;

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'neurology_agent_price' ];

			let i = cols.indexOf(col);
			
			if(row[mins[i]] && this.fdata[cols[i]]){
				let c = this.fdata[cols[i]] - row[mins[i]];
				if(c < 0) c = 0;
				this.fdata[agents[i]] = c;
			}
		},

		async applyDefaultPrice() {
			let row = this.defaultRates;
			this.fpopulate(row);

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_neurology' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'neurology_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'neurology_agent_price' ];

			for(let i=0; i<mins.length; i++){
				if(row[mins[i]] && row[cols[i]]){
					let c = row[cols[i]] - row[mins[i]];
					if(c < 0) c = 0;
					this.fdata[agents[i]] = c;
				}
			}
		},
		doubleRate(type){
			if(type == 'single_view'){
				this.fdata.price_both_view = Number(this.fdata.price_single_view) * 2;
			}else if(type == 'multi_view'){
				this.fdata.price_other_view = Number(this.fdata.price_multi_view) * 2;
			}
		},
		putCenter() {
			if(this.fHasError()) console.log(this.fvalidator);
			if (this.fHasError() || this.saving) return;
			this.saving = true;

			let data = JSON.parse(JSON.stringify(this.fdata));
			console.log(data);
			
			this.post("agent/put-center", data).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				} else if (res.codeExists) {
					alert("Center code already exists.")
				}
			});
		},
		showAddEdit() {
			this.freset();
			setTimeout(()=>{
				this.applyDefaultPrice();
			}, 500)
			this.addModal.show();
		},
	},
}
</script>
<style scoped>
.center-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #a4c4e3;
	border-radius: 10px;
}

.doctor-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d9dfe6;
	border-radius: 10px;
}

.agent-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eec9e9;
	border-radius: 10px;
}

.ecg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eeebc9;
	border-radius: 10px;
}
.pft-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d1ffba;
	border-radius: 10px;
}
.eeg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #c9eee7;
	border-radius: 10px;
}

</style>